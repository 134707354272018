/* eslint-disable */
import React from "react";

export default {
  en: {
    quote:
      "How does Jawg help us? You are responsive. Every time I talk with [Jawg], I get immediate answers and that is really valuable.",
    author: "Florian Bruniaux, CTO",
    sector: "Demographic & Social data analysis",
    interviewees: ["Florian Bruniaux, Chief Technical Officer"],
    service: "Dynamic & Static Maps, Geocoding, Pedestrian Routing",
    geo: "France, Germany",
    blocks: [
      {
        title: "eXplain",
        description: (
          <p>
            <a
              href="https://explain.fr/"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
              target="_blank"
            >
              eXplain
            </a>{" "}
            provides day-to-day support to their customers by giving them better
            ways to understand local data during, for example, an electoral
            campaign. The approach is based on open data analysis (personal data
            on social media are not used) and technology. Ethic and transparency
            are fundamental values for eXplain. As CTO, Florian Bruniaux works
            with his technical team and manage the development of all eXplain
            products.
          </p>
        ),
        img: "clientLogo",
      },
      {
        title: "eXplain & Jawg",
        description: (
          <>
            <p>
              The tools developed by eXplain have specific requirements in terms
              of routing and geocoding.{" "}
              <q>
                We are launching a new product addressing a new market, which
                holds great challenges around data acquisition, data analysis
                and infrastructure.
              </q>{" "}
              The first thing Jawg responded to was the ability to address our
              needs for high usage.
            </p>
            <p>
              <q>
                Maps are one of the biggest features of our production tool.
              </q>
            </p>
          </>
        ),
        img: "usageHighlight",
      },
      {
        title: "",
        description: (
          <p>
            Today, eXplain teams are working on their next product and still
            like to testify about the{" "}
            <q>certain flexibility [of Jawg teams] which is appreciated</q>. For
            Florian Bruniaux, what makes the difference compared to other actors
            is that <q>discussions take place on a human scale</q>.
          </p>
        ),
      },
      {
        title: "",
        description: (
          <p>
            <q>
              How does Jawg help us? You are responsive. Every time I talk with
              [Jawg], I get immediate answers and that is really valuable.
            </q>{" "}
            Florian Bruniaux, CTO
          </p>
        ),
      },
      {
        title: "",
        description: (
          <p>
            In this intense phase of R&amp;D where needs are diverse and
            various, Florian Bruniaux underlines that{" "}
            <q>it is a start up addressing another start up</q> and that{" "}
            <q>there is still a certain proximity</q>.
          </p>
        ),
      },
      {
        title: "Jawg Maps in 3 words?",
        description: "Kind, Flexible, Responsive",
      },
    ],
  },
  fr: {
    quote:
      "Comment Jawg nous aide ? Vous êtes réactifs. À chaque fois que je parle avec [Jawg], j’ai des réponses au tac au tac et ça c’est super appréciable.",
    author: "Florian Bruniaux, CTO",
    sector: "Analyse de données sociodémographiques",
    interviewees: ["Florian Bruniaux, Chief Technical Officer"],
    service: "Dynamic & Static Maps, Geocoding, Routing Piéton",
    geo: "France, Allemagne",
    blocks: [
      {
        title: "eXplain",
        description: (
          <p>
            <a
              href="https://explain.fr/"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
              target="_blank"
            >
              eXplain
            </a>{" "}
            accompagne quotidiennement ses clients dans la compréhension des
            données locales, dans le cadre de campagnes électorales par exemple.
            L’approche est fondée sur l’analyse de données ouvertes (les données
            personnelles des réseaux sociaux ne sont pas utilisées) et la
            technologie. L'éthique et la transparence sont des valeurs
            fondamentales pour eXplain. En tant que CTO, Florian Bruniaux
            orchestre les développements de tous les produits d’eXplain.
          </p>
        ),
        img: "clientLogo",
      },
      {
        title: "eXplain & Jawg",
        description: (
          <>
            <p>
              Les outils créés par eXplain possèdent des besoins spécifiques en
              matière de routing et geocoding.{" "}
              <q>
                &#160;On est en train de lancer un nouveau produit qui est sur
                une nouvelle cible qui a plutôt de gros enjeux sur l'acquisition
                de données, le traitement de données et l’infra.&#160;
              </q>{" "}
              C’est tout d’abord à une problématique de volumétrie auquel Jawg a
              répondu.
            </p>
            <p>
              <q>
                &#160;La cartographie c’est une des grosses features de notre
                outil de production.&#160;
              </q>
            </p>
          </>
        ),
        img: "usageHighlight",
      },
      {
        title: "",
        description: (
          <p>
            Aujourd’hui, les équipes développent des solutions d’avenir et
            trouvent chez Jawg{" "}
            <q>&#160;une certaine flexibilité qui est appréciable&#160;</q>.
            Pour Florian, ce qui fait la différence par rapport à d’autres
            acteurs, c’est{" "}
            <q>
              &#160;que là, il y a un échange un peu plus à taille humaine&#160;
            </q>
            .
          </p>
        ),
      },
      {
        title: "",
        description: (
          <p>
            <q>
              &#160;Comment Jawg nous aide ? Vous êtes réactifs. A chaque fois
              que je parle avec [Jawg], j’ai des réponses au tac au tac et ça
              c’est super appréciable.&#160;
            </q>{" "}
            Florian Bruniaux, CTO
          </p>
        ),
      },
      {
        title: "",
        description: (
          <p>
            Dans cet optique de développement où les besoins sont multiples et
            variés, Florian Bruniaux note{" "}
            <q>
              &#160;c’est une startup qui s’adresse à une autre start up&#160;
            </q>{" "}
            et ajoute{" "}
            <q>&#160;il y a quand même une certaine proximité&#160;</q>.
          </p>
        ),
      },
      {
        title: "Jawg Maps en 3 mots ?",
        description: "Bienveillant, Flexible, Réactif",
      },
    ],
  },
};
